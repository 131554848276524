import { post } from '@/utils/request';

// 列表
const getListReq = (params) => post({
  url: '/store/order.Authorize/getList',
  data: {
    ...params,
  },
});

// 获取员工
const getAuthorizeUserReq = (params) => post({
  url: '/store/authority.User/getAuthorizeUser',
  data: {
    ...params,
  },
});

// 添加授权
const addAuthorizeUserReq = (params) => post({
  url: '/store/order.Authorize/addAuthorizeUser',
  data: {
    ...params,
  },
});

// 授权对象
const updateDiscountReq = (params) => post({
  url: '/store/order.Authorize/updateDiscount',
  data: {
    ...params,
  },
});
export {
  getListReq,
  getAuthorizeUserReq,
  addAuthorizeUserReq,
  updateDiscountReq,
};
