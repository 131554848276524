<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" :content="row.authorize_user_id? '编辑改价权限' : '新增改价授权'">
      </el-page-header>
    </div>
    <div class="form">
      <el-form :model="form" label-width="120px">
        <el-form-item label="选择员工" required v-if="!row.authorize_user_id">
          <el-select v-model="form.store_user_id" placeholder="请选择员工" clearable filterable>
            <template v-for="item in noExistList">
              <el-option :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="品类折扣" required>
          <el-radio-group v-model="is_all_class">
            <!-- <el-button type="primary" plain @click = "treeVisible = true">选择品类</el-button> -->
            <!-- <el-radio :label="10" @click.native="handleAll">全品类</el-radio> -->
            <el-radio :label="10" @click.native = "treeVisible = true">自定义品类</el-radio>
            <el-radio :label="30" @click.native = 'dialogVisible = true'>从已有员工复制品类折扣</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.authorize_class_array.length">
          <div style="display: flex; margin-bottom: 10px;"
              v-for="item in form.authorize_class_array" :key="item.goods_type_id">
            <div style="width: 100px;">{{item.goods_type_name}}</div>
            <el-input v-model="item.discount" style="width: 10%; margin-left: 20px;">
              <template slot="append">折</template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="启用" required>
          <el-radio-group v-model="form.state">
            <el-radio :label="10">开</el-radio>
            <el-radio :label="20">关</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="saveLoading">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="选择品类"
      :visible.sync="treeVisible"
      width="80%"
      :before-close="handleClose">
      <div style="display: flex; justify-content: space-between;">
        <div>
          <el-button type="primary" size="mini" style="margin: 0 0 20px 40px;" @click="handleAll">全选</el-button>
          <el-button type="danger" size="mini" plain style="margin: 0 0 20px 10px;" @click="handleNo">全不选</el-button>
        </div>
        <!-- <div>
          <span style="margin-right: 10px;">从已有员工复制品类折扣</span>
          <el-select v-model="store_user_id" placeholder="请选择员工" clearable filterable @change="handleSelect">
            <template v-for="item in existList">
              <el-option :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
            </template>
          </el-select>
        </div> -->
      </div>
      <el-tree
        class="tree-line"
        ref="tree"
        :data="treeData"
        show-checkbox
        :check-strictly="true"
        :default-checked-keys="checkKeys"
        node-key="goods_type_id"
        :props="defaultProps">
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="从已有员工复制品类折扣"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
        <div>
          <span style="margin-right: 10px;">选择</span>
          <el-select v-model="store_user_id" placeholder="请选择员工" clearable filterable>
            <template v-for="item in existList">
              <el-option :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
            </template>
          </el-select>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListReq,
} from '@/api/goods/goodsConfig/classify';
import { getAuthorizeUserReq, getListReq as getAuth, addAuthorizeUserReq } from '@/api/system/pricingAuthorization';

export default {
  data() {
    return {
      saveLoading: false,
      dialogVisible: false,
      is_all_class: '',
      store_user_id: '',
      existList: [], // 已设置员工
      noExistList: [], // 未设置员工
      checkKeys: [], // 选中的key
      loading: false,
      treeVisible: false,
      form: {
        state: 10,
        is_all_class: 10,
        authorize_class_array: [],
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'goods_type_name',
      },
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  created() {
    this.getAuthorizeUser(0);
    this.getAuthorizeUser(1);
    this.getList();
    if (this.row.authorize_user_id) {
      const row = { ...this.row };
      const form = {
        store_user_id: row.store_user_id,
        state: row.state,
        is_all_class: row.is_all_class,
        authorize_class_array: row.detail,
        authorize_user_id: row.authorize_user_id,
      };
      this.is_all_class = row.is_all_class;
      row.detail.forEach((item) => {
        this.checkKeys.push(item.goods_type_id);
      });
      this.form = form;
    }
  },
  methods: {
    onSubmit() {
      this.saveLoading = true;
      const authorize_class_array = [];
      this.form.authorize_class_array.forEach((item) => {
        const obj = {
          goods_type_id: item.goods_type_id,
          discount: item.discount,
        };
        authorize_class_array.push(obj);
      });
      addAuthorizeUserReq({ ...this.form, authorize_class_array })
        .then((res) => {
          if (res.code === 1) {
            this.saveLoading = false;
            this.$message.success('操作成功');
            this.$emit('onHide', 'getList');
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    handleSubmit() {
      if (this.store_user_id) {
        getAuth({
          store_user_id: this.store_user_id,
        })
          .then((res) => {
            if (res.code === 1) {
              res.data.list[0].detail.forEach((item) => {
                this.checkKeys.push(item.goods_type_id);
              });
              if (this.$refs.tree) {
                this.$refs.tree.setCheckedKeys(this.checkKeys);
              }
              const form = { ...this.form };
              form.authorize_class_array = res.data.list[0].detail;
              this.form = form;
              this.handleClose();
            }
          });
      }
    },
    // 获取员工
    getAuthorizeUser(is_exist) {
      getAuthorizeUserReq({
        is_exist,
      })
        .then((res) => {
          if (res.code === 1) {
            if (is_exist) {
              this.existList = res.data;
            } else {
              this.noExistList = res.data;
            }
          }
        });
    },
    handleNo() {
      this.checkKeys = [];
      this.$refs.tree.setCheckedKeys(this.checkKeys);
    },
    handleAll() {
      const checkKeys = [];
      function poling(arr) {
        arr.forEach((item) => {
          checkKeys.push(item.goods_type_id);
          if (item.children) {
            poling(item.children);
          }
        });
        return checkKeys;
      }
      this.checkKeys = poling(this.treeData);
      this.$refs.tree.setCheckedKeys(this.checkKeys);
    },
    // 获取的key
    getCheckedNodes() {
      const nodes = this.$refs.tree.getCheckedNodes();
      const form = { ...this.form };
      console.log(form);
      // form.authorize_class_array = [];
      // 组装后端需要的数据
      const newArr = [];
      nodes.forEach((item) => {
        let obj = {};
        let m = 0;
        if (form.authorize_class_array.length) {
          form.authorize_class_array.forEach((auth) => {
            if (item.goods_type_id === auth.goods_type_id) {
              obj = {
                goods_type_id: auth.goods_type_id,
                goods_type_name: auth.goods_type_name,
                discount: auth.discount,
              };
            } else {
              m += 1;
              if (m === form.authorize_class_array.length) {
                obj = {
                  goods_type_id: item.goods_type_id,
                  goods_type_name: item.goods_type_name,
                  discount: '',
                };
              }
            }
          });
        } else {
          obj = {
            goods_type_id: item.goods_type_id,
            goods_type_name: item.goods_type_name,
            discount: '',
          };
        }
        newArr.push(obj);
      });
      form.authorize_class_array = newArr;
      this.form = form;
    },
    handleTrue() {
      this.getCheckedNodes();
      this.handleClose();
    },
    handleClose() {
      this.treeVisible = false;
      this.dialogVisible = false;
    },
    getList() {
      let that=this;
      getListReq()
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.treeData = res.data;

            console.log(that.childrenData(res.data))

          }
        });
    },
    childrenData(data){
      console.log(10)
      let count = 0;
      for (let i in data) {
        data[i].index = i;
        if (data[i].children) {
          count++;
         this.childrenData(data[i].children);
        }
        return count;
      }
    },
    goBack() {
      this.$emit('onHide');
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  background: white;
  padding: 20px 0;
  margin-top: 10px;
}
</style>
