<template>
  <div v-if="!addVisible">
    <div class="card" style='display: flex;'>
      <div class="left">
        <el-button type='primary' icon='el-icon-plus' @click="addVisible = true">新增折扣授权</el-button>
      </div>
      <div class='line'></div>
      <div class="middle">
        <el-form :model="form" :inline="true">
          <el-form-item label="员工">
            <el-select v-model="form.store_user_id" placeholder="请选择员工"  filterable  clearable>
              <template v-for="item in staffList">
                <el-option :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="职位">
            <el-select v-model="form.group_id" placeholder="请选择职位"  filterable  clearable>
              <template v-for="item in roleList">
                <el-option :label="item.title" :value="item.id" :key="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right" style="margin-left: auto;">
        <el-button type="primary" @click="chooseModel">切换授权模式</el-button>
      </div>
      <el-dialog
        title="切换授权模式"
        :visible.sync="dialogVisible"
        width="30%">
         <el-radio-group v-model="discount_authorize">
          <el-radio :label="10">收银员(当前登录账号)</el-radio>
          <el-radio :label="20">主销售</el-radio>
        </el-radio-group>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleTrue" :loading="modeLoading">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        height="700"
        border
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              <template v-if="item.field_alias !== 'goods_type_name' && item.field_alias !== 'discount'">
                {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
              </template>
              <template v-if="item.field_alias === 'goods_type_name'">
                <div
                  v-for="(item, index) in scope.row.detail"
                  :key="item.authorize_user_detail_id"
                  style="height: 40px; line-height: 40px;"
                  :class="index + 1 !== scope.row.detail.length? 'border': ''">
                    {{item.goods_type_name}}
                </div>
              </template>
              <template v-if="item.field_alias === 'discount'">
                <div
                  v-for="(item, index) in scope.row.detail"
                  :key="item.authorize_user_detail_id"
                  style="height: 40px; line-height: 40px;"
                  :class="index + 1 !== scope.row.detail.length? 'border': ''">
                    {{item.discount}}折
                </div>
              </template>
            </template>
          </el-table-column>
        </template>
        <template>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleChange(scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="page_num"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
      </el-pagination>
    </div>
  </div>
  <Add v-else @onHide="handleHide" :row="currRow" />
</template>

<script>
import moment from 'moment';
import { myMenuReq } from '@/api/basicLayout';
import { ListRequest as getStaffListReq, roleListReq } from '@/api/authority/menage';
import { getListReq, updateDiscountReq } from '@/api/system/pricingAuthorization';
import Add from './Add.vue';

export default {
  data() {
    return {
      modeLoading: false,
      discount_authorize: '',
      dialogVisible: false,
      currRow: {},
      addVisible: false,
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      loading: false,
      tableData: {
        list: [],
        total: 0,
      },
      form: {},
      staffList: [],
      roleList: [],
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '员工', field_alias: 'realname' },
        { field_text: '职位', field_alias: 'group_name' },
        { field_text: '品类', field_alias: 'goods_type_name' },
        { field_text: '最低折扣', field_alias: 'discount' },
        { field_text: '状态', field_alias: 'state_text' },
      ],
    };
  },
  components: {
    Add,
  },
  created() {
    this.getStaffList();
    this.getRoleList();
    this.getList();
  },
  methods: {
    chooseModel() {
      this.dialogVisible = true;
      this.myMenu();
    },
    myMenu() {
      this.modeLoading = true;
      myMenuReq()
        .then((res) => {
          if (res.code === 1) {
            this.modeLoading = false;
            this.discount_authorize = res.data.user_info.discount_authorize;
          }
        })
        .catch(() => {
          this.modeLoading = false;
        });
    },
    handleTrue() {
      this.modeLoading = true;
      updateDiscountReq({
        discount_authorize: this.discount_authorize,
      })
        .then((res) => {
          if (res.code === 1) {
            this.modeLoading = false;
            this.$message.success('设置成功');
            this.dialogVisible = false;
          }
        })
        .catch(() => {
          this.modeLoading = false;
        });
    },
    resetForm() {
      this.form = {};
      this.page = 1;
      // this.page_num = 15;
      this.getList();
    },
    handleHide(getList) {
      if (getList) {
        this.getList();
      }
      this.addVisible = false;
      this.currRow = {};
    },
    // 获取列表
    getList() {
      this.loading = true;
      getListReq({ ...this.form, page: this.page, page_num: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleChange(row) {
      this.currRow = JSON.parse(JSON.stringify(row));
      this.addVisible = true;
    },
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    // 员工列表
    getStaffList() {
      getStaffListReq({
        page: 1,
        limit: 99999,
      })
        .then((res) => {
          if (res.code === 1) {
            res.data.list.forEach((item) => {
              item.update_time = moment(item.update_time * 1000).format('YYYY-MM-DD HH:mm:ss');
            });
            this.staffList = res.data.list;
          }
        });
    },
    // 职位列表
    getRoleList() {
      roleListReq()
        .then((res) => {
          if (res.code === 1) {
            this.roleList = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.border {
  border-bottom: 1px solid #ddd;
}
.middle {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
/deep/ .el-table .cell {
  padding: 0;
}
</style>
